import { useEffect, useState } from "react";
import "./app.scss";
import { addSudokuStorage, getSudokuStorage } from "./asyncstorage";

const URL_SERVER = "https://ocr.usiobe.com";

function App() {
  const [ image, setImage ] = useState(); 
  const [ oldResult, setOldResult ] = useState([]); 
  const [ processing, setProcessing ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const getOldResult = async() => {
    const res = getSudokuStorage(); 
    if (res)
      setOldResult(res);
  }

  useEffect(() => {
    getOldResult();
  },[])

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  const loadImage = (event) => {
    setProcessing(true); 
    setImage(URL.createObjectURL(event.target.files[0]));

    const callServer = async() => {
      
      setLoading(true);
      let img = await toBase64(event.target.files[0]);
      
      const res = await fetch(URL_SERVER + "/process", {
          body: JSON.stringify({ content: img.split(',')[1] }),
          method: "POST", 
          headers: {
              "Content-Type": "application/json"
          }
      })

      if (res.status) {
        try {
            const result = await res.json(); 
            if (!result.success)
            {
                console.log("Error has not ended successfuly: " + result.message)
                setLoading(false);
                return ;
            }

            let base64 = await result.image;
            if (!base64)
                console.log(`An error occured while parsing image response`)
            else {
                const blob = b64toBlob(base64, "");
                setImage(URL.createObjectURL(blob));

                img = img.split(",")[1]; 

                const id = Date.now();
                await addSudokuStorage({
                    title: "Sudoku xxx", 
                    image_end: base64,
                    image_start: img,
                    date: new Date().toString().split("GMT")[0],
                    id: id.toString()
                })
                getOldResult()
            }
            setLoading(false);

        }catch(err) {
            console.log(`An error occured while parsing image response ${err}`)
        }
      }else{
          console.log("Error response status");
      }
    }

    callServer();
  }

  return (
    <div className="app-container">
      <div className="top">
          <img src="./logo.png" className="logo" />
          <h1><span>OCR -</span> Reliable Octopus Computing</h1>
      </div>

      <div className="main">
        <section className="input-container">
            {
              !processing ? 
              <div className="input-box">
                <input type="file" onChange={loadImage} />
                <img src="./upload.png" />
              </div> :
              <div className="processing-container">
                <img src={image} /> 

                <div className="input-box">
                  <input type="file" onChange={loadImage} />
                  <img src="./upload.png" />
                </div>
                { loading && <p>Processing...</p> }
              </div>
            }
        </section>
        <div className="sep" />
        <section className="old-scan-container">
          {
            oldResult?.map((element, i) => {
              const url1 = URL.createObjectURL(b64toBlob(element.image_start, ""))
              const url2 = URL.createObjectURL(b64toBlob(element.image_end, ""))
              return (
                <div className="old-scan-box" key={i}>
                  <div className="info-container">
                    <p className="title">{element.title}</p>
                    <div className="date-container">
                      <p>{element.date}</p>
                    </div>
                  </div>
                  <div className="pics-part">
                    <img src={url1} className="old-pic" />
                    <img src={url2} className="new-pic" />
                  </div>
                </div>
              )
            })
          }
        </section>
      </div>
      <div className="projects">
        <h2>Projects</h2>
        <p>The Sudoku OCR Project is the flagship project of the first semester for second-year EPITA students.</p>
        <p>This project, like most of EPITA’s projects, is a group project. The goal is to code a software that scans a Sudoku photo, solves it, and returns a completed grid. Therefore, it is a very complete project from image processing to AI (for number recognition) passing through grid detection, graphic interface, of course, a solver, and many else.</p>
        <p>The real difficulty, apart from learning on our own how to make an AI work, and other specific stuff for the project, is that there is no specific time allocated to the project, so we have to do it alongside our normal courses. We nonetheless managed to complete the all-project and make some bonuses, like this website for example, and even a mobile app!</p>
        <p>Our team, Reliable Octopus Computing, mostly known as ROC, is composed of 4 geniuses: Anis Chtourou (the team leader), Loïc Battesti, Ethan Perruzza, and Théo Rogue.</p>
        <div className="project-section">
          <div className="img-container left">
            <img src="logiciel.gif" />
          </div>
          <p>
            <h2>Software</h2>
            This software is the final result of all our efforts and troubles. It’s designed to respect three major points: style, simplicity of use and speed. 
The app has a simple but modern design, so that the user isn’t confused at first sight. A few buttons clearly describing what they do, indicators on the right showing the user the progress made on the sudoku and the near instant various processes make this software one of the best, if not the best, sudoku solver. 
You can check out the source code down below to install our project.
          </p>
        </div>
        <div className="project-section">
          <p>
            <h2>Mobile App</h2>
            Let’s face it, it might be a bit impracticable to need to take the photo with your phone and then put it on your computer to solve the sudoku. That’s why we developed a mobile app just for you!
            The mobile app allows you to run our project on the go! 
            There are two main functionalists :
            <br/>·    Scanning sudokus directly from the app. They will magically be solved by our secret algorithm and the result will be displayed directly on your phone.
            <br/>·    After scanning sudoku, you could also complete it directly on the app by selecting a cell, and drawing the number you want, our AI will recognize and fill the grid for you with that number so that you could have a clean copy of your own-solved sudoku.
            <br/><br/>A bit of history or how did we manage to recognize that well hand-written digits?
            We developed an all-section in the app that allowed us to easily create a huge dataset to train our AI with. We were able to draw digits on the phone screen and tell the AI what digits we drew so that it train with our data and be more efficient.
          </p>
          <div className="img-container right">
            <img src="mobile.gif" />
          </div>
        </div>
      
      </div>
      <div className="members">
        <h2>Members</h2>
        <div className="members-pics-container">
          <div className="members-pics-box">
            <div className="pics-container">
              <img src="./members/anis.jpg" />
            </div>
            <div className="sub-info">
              <p>Anis Chtourou</p>
              <p>Grid detection / artificial intelligence / mobile app</p>
              <p className="hidden-text">Team leader / lack of sleep</p>
            </div>
          </div>
          <div className="sep" />
          <div className="members-pics-box rev">
            <div className="sub-info">
              <p>Théo Rogue</p>
              <p>Binarisation / GTK</p>
              <p className="hidden-text">Our psy / bot</p>
            </div>
            <div className="pics-container">
              <img src="./members/theo.jpg" />
            </div>
          </div>
          
          <div className="sep" />
          <div className="members-pics-box">
            <div className="pics-container">
              <img src="./members/ethan.png" />
            </div>
            <div className="sub-info">
              <p>Ethan Perruzza</p>
              <p>XOR / Python script master</p>
              <p className="hidden-text">Fill 'lorem ipsum' master, <br/>Does not adhere to the management method</p>
            </div>
          </div>
          <div className="sep" />
          <div className="members-pics-box rev">
            <div className="sub-info">
              <p>Loic Battesti</p>
              <p>XOR / Dataset creator</p>
              <p className="hidden-text">Picasso Handwritten creator</p>
            </div>
            <div className="pics-container">
              <img src="./members/loic.png" />
            </div>
          </div>
        </div>
      </div>
      <div className="executables">
        <h2>Source code</h2>
        <div className="links_containers">
          <div className="link">
            <a href="https://github.com/Mzzay/OCR" target="_blank"><img src="./github.png" className="links_icons" /></a>
            <h6 className="link_name">Github Repository</h6>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
