const SUDOKU_KEY = "@sudoku_list"; 
const MAX_ELEM = 3;

export const addSudokuStorage = async (sudoku) => {
    // add sudoku image to async storage
    let sudokuList= await getSudokuStorage();
    sudokuList = sudokuList ? sudokuList : [];
    try {
        console.log("sudoku before concat: ", sudokuList)
        sudokuList = sudokuList.concat(sudoku);
        sudokuList.sort((a,b) => new Date(b.date).getTime() - new Date(a.date).getTime());
        sudokuList = sudokuList.slice(0, MAX_ELEM);

        const jsonValue = JSON.stringify(sudokuList)
        localStorage.setItem(SUDOKU_KEY, jsonValue)
    } catch (e) {
        console.log(`Sudoku hasn't been found ${e}`);
    } 
    
    return ;
}

export const getSudokuStorage = () => {
    // get all sudoku
    let sudokuList = []; 
    try {
        const jsonValue = localStorage.getItem(SUDOKU_KEY);
        return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch(e) {
        console.log("Sudoku hasn't been found")
        return sudokuList;
    }
}

export const clearSudokuStorage = async() => {
    try {
        localStorage.removeItem(SUDOKU_KEY);
        return true;
    }catch(err) {
        console.log("Error while trying to clear");
        return false;
    }
}